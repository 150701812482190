import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


// MEDIA QUERY
ScrollTrigger.matchMedia({

    // only Desktop
    "(min-width: 1200px)": function() {

        // FADE-IN ANIMATION BG-IMAGE
        // gsap.utils.toArray('.gsap-bg-image-fade-in').forEach(image => {
        //     const tl = gsap.timeline({
        //         scrollTrigger: {
        //             trigger: image,
        //             start: "top top",
        //             end: "+=3000",
        //             scrub: 1,
        //             pin: true,
        //             // markers: true
        //         }
        //     })
        //     .from(image, {opacity: 0, duration: 0.2}, 0)
        //     .to(image, {opacity: 0, duration: 0.2}, 0.5)
        // });
        // FADE-IN ANIMATION SECTION
        // gsap.utils.toArray('.gsap-section-fade-in').forEach(section => {
        //     const tl = gsap.timeline({
        //         scrollTrigger: {
        //             trigger: section,
        //             start: "top top",
        //             end: "bottom bottom",
        //             scrub: 1,
        //             // pin: true,
        //             // markers: true
        //         }
        //     })
        //     .from(section, {opacity: 0, duration: 0.1}, 0)
        //     .to(section, {opacity: 0, duration: 0.2}, 0.8)
        // });
        // FADE-IN ANIMATION CONTENT-INSIDE-BG
        // gsap.utils.toArray('.gsap-content-fade-in').forEach(content => {
        //     const tl = gsap.timeline({
        //         scrollTrigger: {
        //             trigger: content,
        //             start: "top top",
        //             end: "+=1000",
        //             scrub: 1,
        //             // markers: true
        //         }
        //     })
        //     .from(content, {opacity: 0, duration: 3}, 4)
        // });

        // Albero slide up
        gsap.to('#imgCorporateTree', {
            scrollTrigger: {
                trigger: '#corporateGoals',
                start: "top center",
                end: "bottom center",
                scrub: 1,
                // markers: true
            },
            y: "-500px",
        });
    },

    // all 
    "all": function() {
        // Fade-in Animation
        gsap.utils.toArray('.gsap-fade-in').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "-10% center",
                    end: "15% center",
                    scrub: 1,
                    // markers: true
                },
                opacity: 0,
            })
        });

        // Slide-up Animation
        gsap.utils.toArray('.gsap-slide-up').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item.closest('div'),
                    start: "top center",
                    end: "50% center",
                    scrub: 1,
                    // markers: true
                },
                y: -200,
                opacity: 0,
            })
        });

        // Slide-down Animation
        gsap.utils.toArray('.gsap-slide-down').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item.closest('div'),
                    start: "top center",
                    end: "50% center",
                    scrub: 1,
                    // markers: true
                },
                y: 200,
                opacity: 0,
            })
        });
        // Slide-down Animation
        gsap.utils.toArray('.gsap-slide-down-auto').forEach(item => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item.closest('div'),
                    start: "-400px center",
                    end: "50% center",
                    scrub: 1,
                    // markers: true
                },
                y: 200,
                opacity: 0,
            })
        });

        // DESIGN parallax creatività
        gsap.utils.toArray('.gsap-slide-down-parallax').forEach(item => {
            var randomNumber = Math.ceil(Math.random() * 200) + 300;

            gsap.from(item, {
                scrollTrigger: {
                    trigger: item.closest('div'),
                    start: "top center",
                    end: "50% center",
                    scrub: 1,
                    // markers: true
                },
                y: randomNumber,
                opacity: 0,
            })
        });

        // Continuos restart videos on Enter
        const restartVideo = (videoElement) => {
            videoElement.currentTime = 0;
            videoElement.playbackRate = 0.5;
            videoElement.play();
        }
        gsap.utils.toArray('.gsap-reload-video').forEach(video => {
            gsap.from(video, {
                scrollTrigger: {
                    trigger: video,
                    start: "-300px center",
                    end: "15% center",
                    onEnter: (self) => {
                        restartVideo(self.trigger)
                    },
                    scrub: 1,
                    // markers: true,
                },
                opacity: 0,
            })
        });

        // Pin image full screen
        const tlImg = gsap.timeline();
        gsap.utils.toArray('.gsap-pin-image').forEach((panel) => {
            tlImg.from(panel, {opacity: 0, duration: 1});
        });
        gsap.utils.toArray('.gsap-pin-image').forEach((image) => {
            ScrollTrigger.create({
                animation: tlImg,
                trigger: '#gsapPinContainerImage',
                start: 'top top',
                end: "+=10000",
                scrub: true,
                pin: true,
            });
        });

        // Custom sliding
        gsap.utils.toArray('.section-scorrimento--primary .sliding--1').forEach((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: "-50% center",
                    end: "200% center",
                    scrub: 2,
                    // markers: true
                },
                x: '30%',
                opacity: 1
            });
        });
        gsap.utils.toArray('.section-scorrimento--primary .sliding--2').forEach((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: "-60% center",
                    end: "200% center",
                    scrub: 2,
                    // markers: true
                },
                x: '-30%',
                opacity: 1
            });
        });
        gsap.utils.toArray('.section-scorrimento--primary .sliding--3').forEach((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: "-70% center",
                    end: "200% center",
                    scrub: 2,
                    // markers: true
                },
                x: '30%',
                opacity: 1
            });
        });
        gsap.utils.toArray('.section-scorrimento--primary .sliding--4').forEach((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: "-80% center",
                    end: "200% center",
                    scrub: 2,
                    // markers: true
                },
                x: '-30%',
                opacity: 1
            });
        });

        gsap.utils.toArray('.section-scorrimento--secondary .sliding--1').forEach((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: "-50% center",
                    end: "50% center",
                    scrub: 2,
                    // markers: true
                },
                x: 0,
                opacity: 1
            });
        });
        gsap.utils.toArray('.section-scorrimento--secondary .sliding--2').forEach((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: "-60% center",
                    end: "50% center",
                    scrub: 2,
                    // markers: true
                },
                x: 0,
                opacity: 1
            });
        });
        gsap.utils.toArray('.section-scorrimento--secondary .sliding--3').forEach((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: "-70% center",
                    end: "50% center",
                    scrub: 2,
                    // markers: true
                },
                x: 0,
                opacity: 1
            });
        });

        // Custom color-letter animation
        gsap.from(gsap.utils.toArray('.color-letter-animation'), {
            scrollTrigger: {
                trigger: '.section-colors-text',
                start: "top center",
                // end: "top center",
                // markers: true
            },
            color: 'gray',
            x: 50,
            opacity: 0,
            rotation: 30,
            duration: 0.75,
            stagger: 0.2,
        });

        // Custom counter animation
        const incrementCount = (element) => {
            const speed = 2;
            const counterNumber = parseInt(document.getElementById(element).dataset.count, 10);

            document.getElementById(element).innerText = 0;

            gsap.to("#"+element, {innerText: counterNumber, duration: 2, snap:"innerText"});
        };
        gsap.from('.box-number--1', {
            scrollTrigger: {
                trigger: '.section-numbers-microfibra',
                start: "top center",
                end: "20% center",
                scrub: 1,
                // markers: true,
                onEnter: (self) => incrementCount('counterNumber1'),
            },
            opacity: 0,
            y: 150,
        });
        gsap.from('.box-number--2', {
            scrollTrigger: {
                trigger: '.section-numbers-microfibra',
                start: "center center",
                end: "70% center",
                scrub: 1,
                // markers: true,
                onEnter: (self) => {
                    incrementCount('counterNumber2_1');
                    incrementCount('counterNumber2_2');
                },
            },
            opacity: 0,
            y: 150
        });
        gsap.from('.box-number--3', {
            scrollTrigger: {
                trigger: '.section-numbers-microfibra',
                start: "bottom center",
                end: "120% center",
                scrub: 1,
                // markers: true,
                onEnter: (self) => incrementCount('counterNumber3'),
            },
            opacity: 0,
            y: 150
        });
    }
});